<template>
<v-card elevation="0">
    <v-card-text>
    <v-data-table :headers="headers"
        :items="docs"
        sort-by="_id"
        :sort-desc="true"
        class="elevation-1"
    >
        <template v-slot:top>
        <v-toolbar flat>
            <v-toolbar-title>Spot By Admin Period</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
        </v-toolbar>
        </template>
        <template v-slot:[`item.period`]="{ item }">
          {{item.period}}
        </template>
        <template v-slot:[`item.agung`]="{ item }">
          <span :title="item.agung">
            {{item.agung || 0}}
          </span>
        </template>
        <template v-slot:[`item.ridho`]="{ item }">
          <span :title="item.ridho">
            {{item.ridho || 0}}
          </span>
        </template>
        <template v-slot:[`item.vindi`]="{ item }">
          <span :title="item.vindi">
            <!-- {{item.lastActive? item.lastActive :'' | moment}}  -->
            {{item.vindi || 0}}
          </span>
        </template>

      </v-data-table>
    </v-card-text>
</v-card>
</template>

<script>
  import moment from 'moment'
  // const short = require('short-uuid');
  export default {
    data: () => ({
      dialog: false,
      headers: [
        { text: 'Periode', value: 'period' },
        { text: 'Agung', value: 'agung' },
        { text: 'Ridho', value: 'ridho' },
        { text: 'Vindi', value: 'vindi' },
      ],
      docs: [],
    }),

    computed: {

    },

    watch: {

    },

    created () {
      this.initialize()
    },
    filters: {
        moment: function (date) {
            if (!date || date=='undefined') return ''
            return moment(date).format('D-MMM-YYYY HH:mm:ss');
        }
    },

    methods: {
      initialize () {
        this.$store.dispatch('reports/getObjs', 'reports/gettotalspotsbyadminperiod').then(response => {
          console.log(response)
            this.docs = response
            console.log(this.docs)
        })
      },

      
    },
  }
</script>