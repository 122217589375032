var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.docs,"sort-by":"_id","sort-desc":true},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Spot By Admin Period")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer')],1)]},proxy:true},{key:"item.period",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.period)+" ")]}},{key:"item.agung",fn:function(ref){
var item = ref.item;
return [_c('span',{attrs:{"title":item.agung}},[_vm._v(" "+_vm._s(item.agung || 0)+" ")])]}},{key:"item.ridho",fn:function(ref){
var item = ref.item;
return [_c('span',{attrs:{"title":item.ridho}},[_vm._v(" "+_vm._s(item.ridho || 0)+" ")])]}},{key:"item.vindi",fn:function(ref){
var item = ref.item;
return [_c('span',{attrs:{"title":item.vindi}},[_vm._v(" "+_vm._s(item.vindi || 0)+" ")])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }